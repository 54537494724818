import React from "react";
import dateFormat from "dateformat";

import app_params from "../../config/params";

const Footer = () => {
  var now = new Date();
  return (
    <footer className={`text-center text-lg-start theme-footer`}>
      <section className="border-bottom mb-1">
        <div className="container text-center text-md-start mt-4">
          <div className="row">
            <div className="col-12 col-lg-6 mx-auto mb-md-0 mb-4">
              <h6 className="text-uppercase fw-bold mb-4 mt-3">Contacto</h6>
              <p>
                <i className="fas fa-home me-3" style={{color:"rgb(151, 27, 47)"}}></i> Avenida Petapa y 32 calle, Ciudad Universitaria, zona 12
              </p>
              <p>
                <a href="mailto:biblioteca.efpem@usac.edu.gt">
                  <i className="fas fa-envelope me-3" style={{color:"rgb(151, 27, 47)"}}></i>
                  biblioteca.efpem@usac.edu.gt
                </a>
              </p>
              {/* <p>
                <a href="tel:1779">
                  <i className="fas fa-phone-alt me-3"></i>
                  (+502) 6634 5515
                </a>
              </p> */}
            </div>
            <div className="col-12 col-lg-6 mx-auto mb-3">
              <h6 className="text-uppercase fw-bold mb-4">
                Biblioteca Escuela de Formación de Profesores de Enseñanza Media - EFPEM -
              </h6>
              <p>Síguenos en nuestras redes sociales</p>
              <div>
                <a
                  href="https://www.facebook.com/EFPEMUSAC.oficial"
                  className="me-4 text-reset"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-facebook-f" style={{color:"rgb(151, 27, 47)"}}></i>
                </a>
                <a
                  href="https://www.instagram.com/efpemusac/"
                  className="me-4 text-reset"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-instagram" style={{color:"rgb(151, 27, 47)"}}></i>
                </a>
                <a
                  href="https://twitter.com/uefpem"
                  className="me-4 text-reset"
                  target="_blank"
                  rel="noreferrer"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" style={{color:"rgb(151, 27, 47)"}} fill="currentColor" class="bi bi-twitter-x" viewBox="0 0 16 16">
                    <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z"/>
                  </svg>
                </a>
              </div>
              {/* <img
                src={`${app_params.basepath}/assets/logos/JUNTOSHACEMOSUNIVERSIDAD_LOGO.png`}
                alt="slogan"
                className="d-inline-block eslogan"
              /> */}
            </div>
          </div>
        </div>
      </section>
      <section className="d-lg-flex flex-row justify-content-center p-4">
        <div className="d-flex flex-row justify-content-center mx-lg-5">
          <span className="glifos-font">GLIFOS - library</span>
          <span className="ms-1">ver. {app_params.version}</span>
        </div>
        <div className="d-flex flex-row justify-content-center mt-3 mt-lg-0 mx-lg-5">
          Copyright &copy; 2000 - {dateFormat(now, "yyyy")} Infolib, S.A.
        </div>
        <div className="d-flex flex-column justify-content-center mt-3 mt-lg-0 mx-lg-5">
          <div className="d-flex flex-row justify-content-center">
            <a href="https://glifos.com" target="_blank" rel="noreferrer">
              <img
                src={`${app_params.basepath}/assets/logos/poweredByGLIFOS.png`}
                alt="poweredByGLIFOS"
                width="63"
                height="30"
                className="rounded"
              />
            </a>
          </div>
        </div>
      </section>
    </footer>
  );
};

export default Footer;
